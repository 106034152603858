.number-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    background: linear-gradient(rgba(0, 66, 101, 0.889),rgba(0, 60, 93, 0.752));
    color: white;
    padding: 20px 0;
}
.number-container img{
    width: 70px;
    height: 70px;
    cursor: pointer;
    transition: transform 0.3s ease;
}
.number-container .counter-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 10px 0;
    padding: 40px 10px;
}
.number-container h2{
    font-size: 35px;
  
}
.number-container p{
    font-size: 20px;
}