.work-process{
    padding: 50px 0;
}
.work-process h2{
    text-align: center;
    font-size: 35px;
}
.work-process h3{
    font-size: 25px;
    text-align: center;
    color: rgb(0, 75, 124);
    font-weight: 500;
}
.work-process-cards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 30px 0;
    margin: 10px 0;
}