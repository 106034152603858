
.about-container{
    background-color: white;
   margin-top: 100px;
   margin-bottom: 20px;
}
.about-container h1{
    font-size: 45px;
    text-align: center;
    padding: 20px 0;
}
.about-content{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
}
.about-left img{
    width:600px;
}
.about-right h2{
    font-size: 45px;
}
.about-right{
    max-width: 700px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
}
.vision-mission{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 90px 0;
    cursor: pointer;
}

.benefits h2{
    text-align: start;
    font-size: 35px;
    margin-left: 19%;
}

.awards{
    display: flex;
    justify-content: start;
    padding: 20px 0;
    margin-left: 19%;
}
.list {
    position: relative;
    width: 50%;
    align-self: center;
  }
  .list h2 {
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  .list ul {
    position: relative;
  }
  .list ul li {
    position: relative;
    left: 0;
    color:black;
    list-style: none;
    margin: 4px 0;
    border-left: 2px solid #0095ff;
    transition: 0.5s;
    cursor: pointer;
  }
  .list ul li:hover {
    left: 10px;
  }
  .list ul li span {
    position: relative;
    padding: 8px;
    padding-left: 12px;
    display: inline-block;
    z-index: 1;
    transition: 0.5s;
    text-align: left;
  }
  .list ul li:hover span {
    color: #111;
  }
  .list ul li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0095ff;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.5s;
  }
  .list ul li:hover:before {
    transform: scaleX(1);
  }

@media (max-width: 768px) {
  .about-left img{
    width:320px;
}
    .list {
        position: relative;
        width: 90%;
        align-self: center;
      }
}