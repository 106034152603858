.footer{
  display: flex;
  flex-direction: column;
  background-color: #161616;
  color: white;
}
.copyright{
  background-color: black;
  padding: 15px 0;
}
.footer a{
  color: white;
}
.footer ul li{
  margin-top: 20px;
  font-size: large;
}
.inner-footer{
  display: flex;
  justify-content: space-around;
  align-items: start;
  flex-wrap: wrap;
  padding: 20px 0;
}
@media(max-width:786px){
  .inner-footer{
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }
  .footer-company{
    width: 93%;
    padding: 0;
  }
  .footer-contact{
    width: 93%;
    padding: 0;
  }
  .footer-contact ul li{
    font-size: 17px;
  }
  .footer-contact img{
    width: 22px;
  }
}
.footer h4{
  font-size: 22px;
  margin: 10px 0;
}
.inner-footer .first{
  display: flex;
  flex-direction: column;
  max-width: 500px;
}
.inner-footer .first .footer-logo{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.inner-footer .first .footer-logo img{
  width: 150px;
}
.inner-footer .first .footer-logo h2{
  font-size: 30px;
}
.inner-footer .first p{
  margin-top: 5px;
  font-size: 18px;
  color: white;
  text-align: left;
  line-height: 1.5;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  &:hover{
    color: #5aaaff;
  }
}
.footer-company{
  max-width: 400px;
  padding: 0 5px;
}
.footer-contact{
  padding: 0 10px;
  max-width: 400px;
}

.footer-contact ul li{
  display: flex;
  align-items: center;
  
}
.footer-contact ul li img{
  margin-right: 10px;
}


