/* General styles */
.home-container {
  background-color: #ffffff;
}
header {
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 999;
  height: 100px;
}
.social-media{
  display: flex;
  flex-direction: row;
  margin-left: -50px;
}
.social-media a{
  background-color: white;
  width: 33px;
  height: 33px;
  align-items: center;
  align-content: center;
  display: flex;
  justify-content: center;
  margin: 3px 10px;
  border-radius: 30px;
  padding: 5px;
}
.social-media a:hover{
  background-color: rgba(169, 0, 0, 0.737);
}
.social-media a img{
  width: 25px;
  height: 25px;

}
header .navbar1 {
  display: none;
}
header .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
header .logo .logo-text h1 {
  font-size: 25px;
  color: rgb(0, 45, 97);
}
header .logo .logo-text p {
  font-size: 13px;
}

header .logo img {
  width: 150px;
}

header .location,
header .phone {
  display: flex;
  justify-content: center;
  align-items: center;
}

header .location p,
header .phone p {
  text-align: center;
}
header .phone .contact-info{
  background-color: rgba(0, 65, 100, 0.753);
  color: white;
  padding: 10px 20px;
  border-radius: 5px 20px;
  box-shadow: 0 0 3px 0 rgb(148, 148, 148);
  border: none;
}
header .phone .contact-info:hover{
  transition: 0.3s ease;
  box-shadow: 0 0 20px 0 rgb(0, 65, 100);
  background-color: aliceblue;
  color: rgb(0, 52, 115);
  border-radius: 20px 5px;
}


@media (max-width: 767px) {
  header .logo-text {
    display: none;
  }
  header {
    height: auto;
    padding: 0;
    z-index: 999;
    top: 0;
    position: sticky;
    backdrop-filter: blur(15px);
    box-shadow: 0 0 5px 0 grey;
    padding: 5px 0;
    
  }
  header .navbar1 {
    display: block;
    overflow-x: hidden;
    border-radius: 16px;
  }
  .yt,
  header .location,
  header .phone {
    display: none;
  }

  header .logo img {
    width: 60px;
    height: 60px;
    transform: scale(1.6);
    object-fit: cover;
  }

  header p {
    font-size: 14px;
  }
}
@keyframes slide-left {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.navbar {
  background-color: rgba(0, 65, 100, 0.753);
  padding: 5px 0;
  top: 0px;
  position: sticky;
  z-index: 999;
}

nav {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0;
}

nav a {
  margin: 0 25px;
  text-decoration: none;
  font-size: 18px;
  border-radius: 5px;
  padding: 3px 10px;
  color: rgb(61, 61, 61);
}

nav a:hover {
  background-color: aliceblue;
  color: #333;
}

nav ul {
  list-style: none;
}

nav ul ul {
  display: none;
}

nav ul li:hover {
  display: block;
}

.responsive {
  display: none;
}

.menu-icon {
  cursor: pointer;
  padding: 3px 7px;
  box-shadow: 0 0 5px 0 grey;
  backdrop-filter: blur(3px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.menu-icon .bar {
  width: 45px;
  height: 3px;
  margin: 6px 0;
  background-color: rgb(0, 60, 100);
  transition: 0.4s;
  z-index: 10;
}

.menu-icon .bar1 {
  width: 35px;
  height: 3px;
  background-color: rgb(0, 60, 100);
  margin: 6px 0;
  transition: 0.4s;
  z-index: 10;
}

.show {
  display: flex;
  flex-direction: column;
  background-color: #1e1e1e;
  z-index: 10;
}

.menu-icon.close div:nth-child(1) {
  transform: rotate(-45deg) translate(-14px, 6px);
}

.menu-icon.close div:nth-child(2) {
  opacity: 0;
}

.menu-icon.close div:nth-child(3) {
  transform: rotate(45deg) translate(-14px, -6px);
}

.show-navitems {
  margin: 0 30px;
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 5px;
  padding: 10px 20px;
  animation: mymove 1s;
}


.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 28.5px;
  margin-top: 10px;
  border-radius: 5px;
  padding: 15px 0;
}

.dropdown-content a {
  color: black;
  padding: 8px 20px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #f1f1f1;
}
#arrow{
  display: inline-block;
  padding: 0 5px;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown:hover #arrow{
  transform: rotate(180deg);
  transition: transform 0.6s ease;
}

@media (max-width: 800px) {
  .menu-icon {
    cursor: pointer;
    padding: 2px 6px;
    box-shadow: none;
    backdrop-filter: blur(3px);
  }

  .menu-icon div {
    width: 27px;
    height: 2.5px;
    background-color: rgb(255, 255, 255);
    margin: 6px 0;
    transition: 0.4s;
    z-index: 10;
  }

  nav {
    display: none;
  }

  .responsive {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .navbar {
    display: none;
  }

  .responsive .nav-logo img {
    width: 80px;
    border-radius: 100%;
    margin-left: 20px;
  }

  .responsive .menu-icon {
    margin-right: 20px;
  }

  .show {
    display: flex;
    flex-direction: column;
    align-items: start;
    height: 90vh;
    background-color: rgba(0, 33, 72, 1);
    width: 100%;
    position: absolute;
    top: 73px;
    left: 0;
    animation: slide-left 1s;
  }

  .show-navitems {
    margin: 0 10px;
    text-decoration: none;
    color: white;
    font-size: 26px;
    border-radius: 5px;
    padding: 10px 20px;
    margin: 20px 0;
  }

  
}



/*Home Content*/
.home{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap-reverse;
  background-color: #f0f4ff;
  padding-bottom: 20px;
}
.home-content {
  height: auto;
  display: flex;
  justify-content: start;
}
.home-content .welcome-banner {
  max-width: 650px;
  padding: 40px 30px;
}
.home-content .welcome-banner h1 {
  font-size: 58px;
  padding: 15px 0;
  letter-spacing: 0.5px;
}
.home-content .welcome-banner h1 strong {
  color: rgb(0, 10, 100);
  text-shadow: 0 0 3px rgba(153, 153, 153, 0.878);
}
.home-content .welcome-banner p {
  text-align: justify;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 600;
  color: #333;
}
.home-content .welcome-banner a  {
 background-color: rgba(0, 65, 100, 0.753);
  margin-top: 30px;
  color: white;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 18px;
  margin: 20px 0;
  box-shadow: 0 0 3px 0 rgb(148, 148, 148);
  color: white;
  border-radius: 5px 20px;
  border: none;
}
.home-content .welcome-banner a:hover {
  transition: 0.3s ease;
  box-shadow: 0 0 20px 0 rgb(0, 65, 100);
  background-color: aliceblue;
  color: rgb(0, 52, 115);
  border-radius: 20px 5px;
}
.home-image img{
  width: 350px;
  transform: scale(1.8);
}
@media (max-width: 768px) {
  .home-content {
    height: 555px;
  }
  .home-content .welcome-banner {
    width: 90%;
    padding: 50px;
    margin: 0;
  }
  .home-content .welcome-banner h1 {
    font-size: 30px;
    padding: 10px 0;
  }
  .home-content .welcome-banner h1 strong {
    font-size: 35px;
    color: rgb(0, 20, 100);
    text-shadow: 0 0 3px rgba(85, 96, 125, 0.878);
  }
  .home-content .welcome-banner p {
    text-align: justify;
    padding: 8px 0;
  }
  .home-content .welcome-banner a {
    border: none;
    color: white;
    padding: 10px 20px;
    margin: 15px 0;
    font-size: 15px;
  }
  .home-image img{
    width: 350px;
    transform: scale(1.1);
  }
}
.whatsapp-button-fixed {
  position: fixed;
  bottom: 35px;
  right: 35px;
  border-radius: 100px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 50px;
  height: 50px;
  z-index: 1000;
  text-decoration: none;
}

.whatsapp-button-fixed img {
  width: 50px;
  height: 50px;
}
@media (max-width: 789px) {
  .whatsapp-button img {
    width: 50px;
    height: 50px;
  }
}
/* Learn More*/
.box3{
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.box3 .about{
  width: 50%;
  color: white;
  background-color: rgb(0,60,100);
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.box3 .contact{
  width: 50%;
  color: white;
  background-color: rgba(0, 92, 129, 0.525);
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
.box3 .arrow{
  border: 3px solid white;
  color: white;
  width: 26px;
  height: 26px;
  text-align: center;
  align-items: center;
  margin: 0 auto;
}
.box3 .about .arrow:hover{
  background-color: white;
  color: rgb(40,50,100);
}
.box3 .contact .arrow:hover{
  background-color: white;
  color: rgb(1,159,224);
}
.box3  p{
  padding: 10px;
  font-weight: 600;
}
.box3  h2{
  padding: 10px;
  font-size: 35px;
}
@media (max-width:800px){
  .box3{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .box3 .about{
    padding: 10px 100px;
  }
  .box3 h2{
    font-size: 25px;
  }
  .box3 .contact{
    padding: 10px 100px;
  }
}
.title-container{
  text-align: center;
  margin-bottom: 30px;
  background: linear-gradient(rgba(25, 25, 25, 0.6), rgba(25, 25, 25, 0.6)), url("title-bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding:60px 0;
  color: white;
}
.title-container h1{
  font-size: 60px;
  font-weight: bold;
  margin-bottom: 20px;
}