.scard {
  margin: 60px 0;
}

.scard img {
  width: 180px;
  height: 180px;
}

.scard h2 {
  text-align: start;
  margin-top: 20px;
  padding: 0 15px;
}

.scard p {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 15px;
}

.fcard {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.rcard {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/* Responsive design */
@media (max-width: 1200px) {
  .scard p {
    max-width: 700px;
  }
}

@media (max-width: 992px) {
  .scard p {
    max-width: 600px;
  }
}

@media (max-width: 768px) {
  .scard p {
    max-width: 90%;
  }

  .fcard,
  .rcard {
    justify-content: center;
  }
}

@media (max-width: 576px) {
  .scard p {
    max-width: 90%;
    padding: 0 10px;
  }

  .fcard,
  .rcard {
    flex-direction: column;
    align-items: center;
  }
}

.about-card {
  width: 300px;
  padding: 20px;
  border-radius: 20px;
  background: #ffffff;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
  text-align: center;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.about-card img {
  width: 150px;
}
.about-card:hover {
  transition: transform 0.3s ease;
  transform: scale(1.1);
}
/*Service Card*/
.service-card {
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 20px;
  padding: 40px 30px;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff;
}
.service-card img {
  width: 120px;
}
.service-card h2 {
  padding: 10px;
  text-align: center;
}
.service-card p {
  font-size: 16px;
  font-weight: 600;
  color: #5d5d5d;
  text-align: center;
}
.service-card a{
  border-radius: 5px 20px;
  padding: 10px 30px;
  color: white;
  background-color: rgb(0, 60, 121);
  margin-top: 28px;
}
.service-card a:hover{
  transition: 0.3s ease;
  box-shadow: 0 0 20px 0 rgb(0, 65, 100);
  background-color: aliceblue;
  color: rgb(0, 52, 115);
  border-radius: 20px 5px;
}
/*Work Card*/
.work-card {
  width: 240px;
  padding: 50px 10px;
  margin: 30px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #bebebe, -20px -20px 60px #ffffff;
}
.work-card:hover{
  transform: scale(1.1);
  transition: transform 0.3s ease;
  box-shadow: 20px 20px 60px #858585, -20px -20px 60px #ffffff;
}
.work-card img {
  width: 80px;
  padding: 10px 5px;
}
.work-card #number {
  font-size: 55px;
  font-weight: bold;
  color: #414141;
  margin-top: -30px;
  color: rgb(0, 60, 121);
  text-align: start;
}
.work-card p{
  color: #414141;
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
}
/*Contact card*/
.contact-card{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #faebd7;
  box-shadow:  10px 10px 30px #d5c8b7,
             -10px -10px 30px #fffff7;
  width: 80%;
  padding:30px 10px;
  border-radius: 10px;
  margin: 80px 0;
}
.contact-card img{
  width: 400px;
  padding: 0 30px;
}
.contact-card .contact-info{
  max-width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding: 0 30px;
}
.contact-card .contact-info p{
  margin-bottom: 20px;
  color: rgb(0,60,120);
  font-size: larger;
}
.contact-card .contact-info h2{
  font-size: 45px;
  text-align: start;
}
.contact-card .contact-info a{
  background-color: rgba(0, 65, 100, 0.753);
  margin-top: 30px;
  color: white;
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 18px;
  margin: 20px 0;
  box-shadow: 0 0 3px 0 rgb(148, 148, 148);
  color: white;
  border-radius: 5px 20px;
  border: none;
}
.contact-card .contact-info a:hover{
  transition: 0.3s ease;
  box-shadow: 0 0 20px 0 rgb(0, 65, 100);
  background-color: aliceblue;
  color: rgb(0, 52, 115);
  border-radius: 20px 5px;
}
@media(max-width:789px){
  .contact-card{
    width: 95%;
    flex-direction: column;
    align-items: center;
    padding:20px 0;
    margin: 40px 0;
  }

  .contact-card img{
    width: 90%;
    padding: 0;
    margin-bottom: 15px;
  }
  .contact-card .contact-info h2{
    font-size: 35px;
    text-align: start;
  }
}