.my-service-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 50px;
}
.my-service-info{
    max-width: 750px;
    padding: 10px 20px;

}
.my-service-info h1{
    padding: 10px 0;
    font-size: 45px;
}
.my-service-info p{
    font-size: 18px;
    line-height: 160%;
}
.my-service-container img{
    max-width: 630px;
    height: auto;
}

/*Serve*/
.serve-container{
  display: flex;
   flex-wrap: wrap;
  justify-content: space-evenly;
 
  align-items: center;
}
.serve-container .serve-item{
  max-width: 600px;
}
.serve-container .serve-item h3{
  font-size: 35px;
}
.serve-container img{
  max-width: 630px;
}
.serve-container .benefits{
    margin-top: 0;
}
@media(max-width:789px){
    .benefits{
        margin-top: 0;
    }
    .serve-container img{
      max-width: 100%;
    }
    .serve-container .serve-item h3{
      font-size: 28px;
    }
    .my-service-container img{
      max-width: 100%;
      height: auto;
  }
}
.awards{
    display: flex;
    justify-content: start;
    padding: 20px 0;
    margin-left: 5.5%;
}
.list {
    position: relative;
    align-self: center;
  }
  .list h2 {
    font-weight: 700;
    letter-spacing: 1px;
    margin-bottom: 10px;
  }
  .list ul {
    position: relative;
  }
  .list ul li {
    position: relative;
    left: 0;
    color:black;
    list-style: none;
    margin: 4px 0;
    border-left: 2px solid rgb(0,65,100);
    transition: 0.5s;
    cursor: pointer;
  }
  .list ul li:hover {
    transition: 0.3s ease;
    left: 5px;
    color: white;
  }
  .list ul li span {
    position: relative;
    padding: 8px;
    padding-left: 12px;
    display: inline-block;
    z-index: 1;
    transition: 0.5s;
    text-align: left;
  }
  .list ul li:hover span {
    color: white;
  }
  .list ul li:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(0,65,100);
    color: white;
    transform: scaleX(0);
    transform-origin: left;
    transition: 0.5s;
  }
  .list ul li:hover:before {
    transform: scaleX(1);
  }

@media (max-width: 768px) {
    .why-left img{
        max-width:320px;
    }
    .list {
        position: relative;
        width: 90%;
        align-self: center;
      }
}
